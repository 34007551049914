import Link from "next/link";
import Button from "./Button";
import MetamateLogo from "./MetamateLogo";
import { Section } from "./Section";


export function Footer() {
    return <div className="bg-black w-full text-white py-12">
    <Section placeItems="center text-white">
      <div className="flex flex-col md:flex-row justify-between place-content-start md:place-items-start gap-6">
        <div className="mt-2"><MetamateLogo size={3} sizeMd={4}/>
        <div className="mb-8 mt-4 md:mt-8">powered by GPT-3</div>
        </div>
        
        <div>
          <a href="https://twitter.com/ChatmateAI"><Button bg="white"><div className="font-bold text-black">Follow us on Twitter</div></Button></a>
          {/*<div>Instagram</div>*/}
          <div className="mt-6"><Link href="/tos">Terms</Link> & <Link href="/privacy">Privacy</Link></div>
        </div>

        <div>
          <div className="font-bold"><a href="https://discord.com/invite/fMtqG3Cpbb"><Button >Join us on Discord</Button></a></div>
          <div className="mt-6">&copy; 2023 BENPIRE</div>
        </div>
      </div>
      

    </Section>
  </div>
}