import Link from "next/link";


export default function MetamateLogo({size=6, sizeMd=8}) {
    return (
        <Link href="/"><div className={"m-auto text-" + size + "xl md:text-" + sizeMd + "xl "}>
            <span className="font-thin tracking-metamate">chat</span>
            <span className="font-bold tracking-metamate">mate</span>
            <span className={"font-thin tracking-metamate" + " text-" + (size)  + "xl md:text-" + (sizeMd ) + "xl "}>.ai</span>
        </div></Link>
    )
}