import Head from "next/head";
import { useEffect } from "react";
import ReactGA from "react-ga4";

ReactGA.initialize("G-2FWDPQXDLF");

export function HeaderMeta({title="Chatmate AI - Artificial people to be friends with"}) {
    useEffect(() => {
        
      }, []);
    
    return <Head>
            <title>{title}</title>
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta
                name="description"
                content="Revolutionize relationships with machines instead of just humans. Chatmates are artifical intelligent persons with simulated lifes and emotions you can chat with and become friends."
            />
        </Head>
}