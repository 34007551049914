import Image from "next/image";
import { useState } from "react";
import Button from "./Button";

function OneItem({name, onSelect, selected}) {
    return <div className="relative" onClick={() => {
        onSelect(name);
    }}><img style={{filter: !content[name] ? "grayscale(1)" : "none"}} src={"/" + name.toLowerCase() + "-logo.png"} className="w-12 md:w-28 h-auto cursor-pointer" alt={"Choose " + name + " messenger"}/>
        {selected === name && <div className="absolute -bottom-9 left-1/4"><div className="arrow-up"></div></div>}
    </div>
}

const content = {
    "WhatsApp":  <span><div className="font-bold text-3xl md:text-4xl text- my-6">+41 78 238 67 31</div></span>,
};

export default function StartWithMessengers({}) {
    const [selected, setSelected] = useState("WhatsApp");
    
    function onSelect(name) {
        console.log("sellected", name);
        setSelected(name);
    }

    return <div className="relative">
        
            <div className="flex flex-row my-8 gap-10 justify-center">
                <OneItem name="WhatsApp" onSelect={onSelect} selected={selected}/>
                <OneItem name="Telegram" onSelect={onSelect} selected={selected}/>
                <OneItem name="Instagram" onSelect={onSelect} selected={selected}/>
                <OneItem name="Messenger" onSelect={onSelect} selected={selected}/>
            </div>

            {selected ? <div className="relative w-full bg-white left-0 p-8 text-left flex flex-row justify-between place-items-start place-content-start">
                {/*<div className="absolute top-2 right-2 cursor-pointer" onClick={() => {
                    setSelected(undefined);
                }}>X</div>*/}
                
                <div className="flex-grow">
                    <div className="text-3xl mb-4 font-bold">{selected}</div>
                    <div className="text-xl">{content[selected] ? <><div>To start send a message to {content[selected] } </div><div className="md:hiddenn w-full text-center text-2xl"><a href="https://wa.me/41782386731?text=start" target="_blank" rel="noreferrer"><Button bg="primary">Start chat now</Button></a></div></>: <div className="py-8">coming soon</div>}</div>
                </div>
                {content[selected] && <div><img src={"/" + selected.toLowerCase() + "_link.png"} className="ml-4 w-60 m-auto hidden md:block" alt={"Scan to start"}/></div>}
            </div>
            : <div>...</div>} 
        </div>
    
}